import {Container, Grid} from "@mui/material";
import {plainToClass} from 'class-transformer';
import React, {Component} from "react";
import * as api from '../infrastructure/api/CDNScoutAPI';
import '../styles/Global.css';
import CssBaseline from "@mui/material/CssBaseline";
import {ApplicationInNetworkCachedDataDTO} from "../infrastructure/dto/ApplicationInNetworkCachedDataDTO";
import {Bar, CartesianGrid, ComposedChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import exchangePoint from "../img/exchange_point_transparent.png";

const whiteColor = '#fff';
const pinkColor = '#DD5A9E';

class InNetworkCachedAppRate {
    appName?: string;
    inNetworkCacheRate?: string;
}

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '80%',
    maxHeight: '80%%',
});

class TopInNetworkCachedApplications extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {topInNetworkCachedApplications: []};
    }

    async componentDidMount() {
        const topInNetworkCachedApplicationsResponse: [] = await api.getTopInNetworkCachedApplications();
        let topInNetworkCachedApplications: ApplicationInNetworkCachedDataDTO[] = plainToClass(ApplicationInNetworkCachedDataDTO, topInNetworkCachedApplicationsResponse);
        console.log('TopInNetworkCachedApplications.topInNetworkCachedApplications', topInNetworkCachedApplications)

        let inNetworkCachedApps: InNetworkCachedAppRate[] = []
        topInNetworkCachedApplications.forEach(app => {
            if (app.appName != null && app.appName !== '') {
                inNetworkCachedApps.push({
                    'appName': app.appName,
                    'inNetworkCacheRate': `${(app.inNetworkCacheInRate!! * 100).toFixed(2)}`
                })
            }
        });

        const chunkSize = 15;
        if (inNetworkCachedApps.length > chunkSize) {
            inNetworkCachedApps = inNetworkCachedApps.slice(0, chunkSize)
        }

        this.setState({
            topInNetworkCachedApplications: inNetworkCachedApps
        });
    }

    render() {
        return (
            <Container>
                <CssBaseline/>
                <Typography variant="h5" color="text.primary" align={"center"} marginBottom={"30px"}
                            style={{fontWeight: 600}}>
                    Ranking de aplicaciones cacheadas in-network
                </Typography>

                <Grid container
                      spacing={0}
                      justifyItems={"center"}
                      alignItems={"center"}
                      flexDirection={"row-reverse"}
                      marginBottom={"30px"}>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container justifyContent="center"
                          alignContent={'center'}>
                        <Typography variant="h6" color="text.primary" align={"center"} marginBottom={"10px"}
                                    style={{wordWrap: "break-word"}}>
                            ¿Que son las in-network caches?
                        </Typography>

                        <Typography variant="subtitle1" color="text.primary" align={"center"} marginBottom={"10px"}>
                            El despliegue geográfico de las CDNs involucra múltiples componentes del sistema distribuido
                            y de su infraestructura. A lo largo de sus diferentes ubicaciones, las CDNs instalan
                            sistemas
                            de almacenamiento (ej. réplicas), puntos de ingreso/egreso a sus redes, servicios de
                            cómputo
                            (generalmente arreglos de servidores), routers, switches, load balancers, entre otros.
                        </Typography>
                        <Typography variant="subtitle1" color="text.primary" align={"center"} marginBottom={"10px"}>
                            Este equipamiento puede estar presente dentro de la infraestructura de proveedores de
                            internet (del inglés Internet Service Providers, ISPs), conocido como in-network cache o
                            puntos de intercambio de tráfico (del inglés Internet Exchange Points, IXPs).
                            Por medio de estos recursos las CDNs pueden incrementar la experiencia de usuario.
                        </Typography>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container justifyContent="center"
                          alignContent={'center'}>
                        <Img src={exchangePoint}/>
                    </Grid>
                </Grid>


                <ResponsiveContainer width="99%" height={600}>
                    <ComposedChart
                        layout="vertical"
                        width={500}
                        height={400}
                        data={this.state.topInNetworkCachedApplications}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid color={"rgba(10, 10, 10, 0.2)"} stroke={"rgba(184, 184, 184, 0.2)"}/>
                        <XAxis type="number">
                            <Label value="% del tráfico entrante" position="bottom" style={{fill: whiteColor}}/>
                        </XAxis>
                        <YAxis dataKey="appName" type="category" scale="band" width={300} style={{fill: whiteColor}}>
                        </YAxis>
                        <Tooltip/>
                        {/*<Legend/>*/}
                        <Bar dataKey="inNetworkCacheRate" name="% del tráfico entrante" barSize={18} fill={pinkColor}
                             isAnimationActive={true}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </Container>
        );
    }
}

export default TopInNetworkCachedApplications;
