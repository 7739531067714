import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import 'reflect-metadata';
import Home from './Home';
import Credits from "./Credits";

function App() {
    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<Home/>}></Route>
                    <Route path="/credits" element={<Credits/>}></Route>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
