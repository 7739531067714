import {Container, Grid, List, ListItemButton} from "@mui/material";
import Typography from '@mui/material/Typography';
import {plainToClass} from 'class-transformer';
import React, {Component} from "react";
import * as api from '../infrastructure/api/CDNScoutAPI';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import {ApplicationDTO} from "../infrastructure/dto/ApplicationDTO";
import '../styles/Global.css';
import AndroidIcon from '@mui/icons-material/Android';
import CssBaseline from "@mui/material/CssBaseline";

class MostConsumingApps extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {topApplicationsByIncomingData: []};
    }

    async componentDidMount() {
        const topApplicationsByIncomingDataResponse: [] = await api.getTopApplicationsByIncomingData();
        let topApplicationsByIncomingData: ApplicationDTO[] = plainToClass(ApplicationDTO, topApplicationsByIncomingDataResponse);

        const chunkSize = 16;
        topApplicationsByIncomingData = topApplicationsByIncomingData.filter((app) => app.appName != null && app.appName !== '')
        if (topApplicationsByIncomingData.length > chunkSize) {
            topApplicationsByIncomingData = topApplicationsByIncomingData.slice(0, chunkSize)
        }

        console.log('MostConsumingApps.topApplicationsByIncomingData', topApplicationsByIncomingData)

        this.setState({
            topApplicationsByIncomingData: topApplicationsByIncomingData
        });
    }

    render() {
        return (
            <Container>
                <CssBaseline/>
                <Typography variant="h5" color="text.primary" align={"center"} marginBottom={"10px"} style={{ fontWeight: 600 }}>
                    Aplicaciones que más solicitan datos
                </Typography>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container justifyContent="center">
                    <List dense={false} sx={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateAreas: '"column-1 column-2"'}}>
                        {this.state.topApplicationsByIncomingData.map((item: ApplicationDTO, index: number) => {
                            return (
                                <ListItem key={item.appPackage}>
                                    <ListItemButton component="a" target="_blank"
                                                    href={"https://play.google.com/store/apps/details?hl=es&id=" + item.appPackage}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                {item.appName == null &&
                                                    <AndroidIcon></AndroidIcon>
                                                }
                                                {item.appName != null &&
                                                    <div className="image-container">
                                                        <img src={item.appLogoURL} alt={""}/>
                                                    </div>
                                                }
                                            </Avatar>
                                        </ListItemAvatar>
                                        {item.appName == null &&
                                            <ListItemText
                                                primary={item.appPackage}
                                                secondary={"Sin categoría"}
                                            />
                                        }
                                        {item.appName != null &&
                                            <ListItemText
                                                primary={item.appName}
                                                secondary={"Categoría: " + item.appCategory}
                                            />
                                        }
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
            </Container>
        );
    }
}

export default MostConsumingApps;
