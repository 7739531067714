import React, {useState} from "react";
import {HeaderWrapper} from "../styles/HeaderWrapperStyles";
import MenuButton from "./MenuButton";
import {NavbarWrapper} from "../styles/NavbarWrapperStyles";
import {Box, Button, CssBaseline, GlobalStyles} from "@mui/material";

function Header() {
    const hoverColor = '#DD5A9E'
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    function headerBoxButtonElement(text: string, link: string, handleClick: any) {
        return (
            // <Box mx={2}>
            <Box>
                <Button href={link} style={{fontWeight: 600}} sx={{
                    color: (theme) => `${theme.palette.text.primary}`,
                    ':hover': {
                        bgcolor: hoverColor,
                        color: 'text.primary',
                    },
                    px: [2, 2],
                    marginTop: '10px',
                    marginBottom: '10px'
                }} onClick={handleClick}>{text}</Button>
            </Box>
        )
    }

    return (
        <HeaderWrapper>
            <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}}/>
            <CssBaseline/>
            <NavbarWrapper open={open}>

                {headerBoxButtonElement('Data', "/#container-usage-summary", handleClick)}
                {headerBoxButtonElement('Descargar', "/#container-download-application", handleClick)}
                {headerBoxButtonElement('Legal', "/#container-footer", handleClick)}
                <Box>
                    <Button href={"/credits"} style={{fontWeight: 600}} sx={{
                        color: (theme) => `${theme.palette.text.primary}`,
                        ':hover': {
                            bgcolor: hoverColor,
                            color: 'text.primary',
                        },
                        px: [2, 2],
                        marginTop: '10px',
                        marginBottom: '10px'
                    }}>Créditos</Button>
                </Box>
            </NavbarWrapper>

            <MenuButton open={open} handleClick={handleClick}/>
        </HeaderWrapper>
    );
}

export default Header;

