import {Container, Grid, List, ListItemButton} from "@mui/material";
import Typography from '@mui/material/Typography';
import {plainToClass} from 'class-transformer';
import React, {Component} from "react";
import * as api from '../infrastructure/api/CDNScoutAPI';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import {AutonomousSystemDTO} from "../infrastructure/dto/AutonomousSystemDTO";
import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import '../styles/Global.css';
import CssBaseline from "@mui/material/CssBaseline";

class TopDestinationAutonomousSystemsChart extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {topDestinationAutonomousSystems: []};
    }

    async componentDidMount() {
        const topDestinationAutonomousSystemsResponse: [] = await api.getTopDestinationAutonomousSystems();
        let topDestinationAutonomousSystems: AutonomousSystemDTO[] = plainToClass(AutonomousSystemDTO, topDestinationAutonomousSystemsResponse);

        const chunkSize = 10;
        if (topDestinationAutonomousSystems.length > chunkSize) {
            topDestinationAutonomousSystems = topDestinationAutonomousSystems.slice(0, chunkSize)
        }

        console.log('TopDestinationAutonomousSystemsChart.topDestinationAutonomousSystems', topDestinationAutonomousSystems)

        this.setState({
            topDestinationAutonomousSystems: topDestinationAutonomousSystems
        });
    }

    render() {
        return (
            <Container>
                <CssBaseline/>
                <Grid container spacing={0}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container justifyContent="center">
                        <Typography variant="h5" color="text.primary" align={"center"} marginBottom={"10px"}
                                    style={{fontWeight: 600}}>
                            Principales proveedores de datos (*)
                        </Typography>

                        <List dense={false}>
                            {this.state.topDestinationAutonomousSystems.map((item: AutonomousSystemDTO, index: number) => {
                                return (
                                    <ListItem key={item.asCode}>
                                        <ListItemButton sx={{py: [0, 0]}} component="a" target="_blank"
                                                        href={"https://www.bigdatacloud.com/asn-lookup/AS" + item.asCode}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <BorderOuterIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={"AS" + item.asCode}
                                                secondary={item.asOrganization?.slice(item.asOrganization?.indexOf(" "))}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                        <Typography variant="body2" color="text.secondary" align={"center"} marginBottom={"10px"}>
                            (*): excluidas in-network caches
                        </Typography>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container justifyContent="center"
                          alignContent={'center'}>
                        <Typography variant="subtitle1" color="text.primary" align={"center"} marginBottom={"20px"}>
                            Internet se compone de una red de redes y los sistemas autónomos (del inglés <Typography
                            display="inline" variant="subtitle1" style={{fontWeight: 600}}>
                            Autonomous Systems
                        </Typography>, AS) son las grandes redes que lo componen. Todo dispositivo conectado a internet
                            está conectado a un sistema autónomo.
                        </Typography>
                        <Typography variant="subtitle1" color="text.primary" align={"center"} marginBottom={"20px"}>
                            Un sistema autónomo es una red o grupo de redes que comparten una misma política de ruteo.
                            Estas políticas de ruteo son ejecutadas por los routers y son las encargadas de determinar
                            el camino por el cual los paquetes <Typography
                            display="inline" variant="subtitle1" style={{fontWeight: 600}}>
                            IP
                        </Typography> (del inglés Internet Protocol, IP) tienen que viajar
                            para llegar desde su origen al destino. Cada uno de los sistemas autónomos controla un
                            conjunto específico de direcciones IP que son anunciadas hacia otros sistemas autónomos
                            mediante el protocolo <Typography
                            display="inline" variant="subtitle1" style={{fontWeight: 600}}>
                            BGP
                        </Typography> (del inglés, Border Gateway Protocol).
                        </Typography>
                        <Typography variant="subtitle1" color="text.primary" align={"center"} marginBottom={"20px"}>
                            Generalmente estos sistemas autónomos son operados por organizaciones como los <Typography
                            display="inline" variant="subtitle1" style={{fontWeight: 600}}>
                            ISPs
                        </Typography> (del
                            inglés, Internet service provider), empresas de tecnologías, gobiernos, universidades, etc.
                        </Typography>

                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default TopDestinationAutonomousSystemsChart;
