import {styled} from "@mui/material/styles";
import * as React from "react";
import {Component} from "react";
import {Grid} from "@mui/material";
import fiubaLogo from '../img/logo_FIUBA.png';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '50%',
    maxHeight: '50%',
});
const fiubaURL = 'https://fi.uba.ar/'

class FiubaFooter extends Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
    }

    handleClick() {
        const newWindow = window.open(fiubaURL, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {
        return (
            <Grid container spacing={0}
                  marginTop={"20px"}
                  justifyContent={"center"}
                  justifyItems={"center"}
                  alignItems={"center"}>
                <Grid item xs={12} paddingBottom={4}
                      justifyContent={"center"}
                      justifyItems={"center"}
                      alignItems={"center"}>
                    <Img src={fiubaLogo} width={300} sx={{borderRadius: 2, cursor: 'pointer'}}
                         onClick={this.handleClick}/>
                </Grid>
                {/*<Grid item xs={6} paddingTop={8} paddingBottom={8}>*/}
                {/*    <Typography variant="h5" color="text.primary" align={"center"}>*/}
                {/*        Facultad de Ingeniería, UBA*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="subtitle1" color="text.primary" align={"center"}>*/}
                {/*        Departamento de Computación*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="subtitle2" color="text.primary" align={"center"}>*/}
                {/*        Av. Paseo Colón 850 - C1063ACV - Buenos Aires - Argentina*/}
                {/*    </Typography>*/}
                {/*</Grid>*/}
            </Grid>
        )
    }
}

export default FiubaFooter