import Container from '@mui/material/Container';
import * as React from 'react';
import {Card, CardContent, CardHeader, createTheme, IconButton, ThemeProvider} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import iconLinkedin from '../img/icon-linkedin.png';
import Avatar from "@mui/material/Avatar";
import Header from "./Header";

// Required for module augmentation
declare module '@mui/material/styles' {
    interface Theme {
    }

    interface ThemeOptions {
    }
}

const customTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#1E1E1E',
        },
    },
});
const cardBackgroundColor = '#2C2A2B'
const urlLinkedinTBotalla = 'https://www.linkedin.com/in/tbotalla/'
const urlLinkedinMRuizEchazu = 'https://www.linkedin.com/in/manuel-ruiz-echaz%C3%BA-b9440913b/'

function Credits() {

    function memberCard(urlLinkedin: string, name: string, backgroundColor: any) {
        return <Card sx={{maxWidth: 345, mb: 2, backgroundColor: backgroundColor, borderRadius: '10px'}}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe">M</Avatar>
                }
                action={
                    <IconButton aria-label="settings" target="_blank" href={urlLinkedin}>
                        <img src={iconLinkedin} style={{width: 35, height: 35, textIndent: 50}}/>
                    </IconButton>
                }
                title={name}
                titleTypographyProps={{variant: 'subtitle1'}}
            />
        </Card>;
    }

    function tutorCard(name: string, backgroundColor: any) {
        return <Card sx={{
            maxWidth: 345,
            mb: 2,
            backgroundColor: backgroundColor,
            borderRadius: '10px'
        }}>
            <CardHeader avatar={<Avatar aria-label="recipe">E</Avatar>}
                        title={name} titleTypographyProps={{variant: 'subtitle1'}}/>
        </Card>;
    }

    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline/>
            <React.Fragment>
                <Header></Header>
                <Container id="container-credits" disableGutters component="main" sx={{py: [2, 2],}}>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Créditos
                    </Typography>
                    <Card sx={{my: [2, 2], minWidth: 275, borderRadius: '20px'}}>
                        <CardContent sx={{mx: [2, 2], my: [2, 0]}}>
                            <Typography component="h6"
                                        variant="h6"
                                        align="left"
                                        color="text.primary"
                                        gutterBottom
                                        sx={{mb: 3}}
                            >
                                CDN Scout es un trabajo desarrollado por estudiantes de la Facultad de Ingeniería de la
                                Universidad de Buenos Aires (UBA) en el marco de un Trabajo Profesional de Ingeniería en
                                Informática.
                            </Typography>
                            <Typography component="h6"
                                        variant="h6"
                                        align="left"
                                        color="text.primary"
                                        gutterBottom>Integrantes</Typography>

                            {memberCard(urlLinkedinMRuizEchazu, "Manuel Ruiz Echazú", cardBackgroundColor)}
                            {memberCard(urlLinkedinTBotalla, "Tomás Enrique Botalla", cardBackgroundColor)}

                            <Typography component="h6"
                                        variant="h6"
                                        align="left"
                                        color="text.primary"
                                        gutterBottom
                                        sx={{mt: 3}}>Tutores</Typography>

                            {tutorCard("Dr. Ing. Esteban Carisimo", cardBackgroundColor)}
                            {tutorCard("Dr. Ing. Mariano Gastón Beiró", cardBackgroundColor)}
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        </ThemeProvider>
    );
}

export default Credits;