import Container from '@mui/material/Container';
import React, {Component} from "react";
import appPreview from '../img/cdn_scout_hero.png';
import '../styles/ApplicationScreenshots.css';
import {styled} from "@mui/material/styles";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '80%',
    maxHeight: '80%%',
});

class ApplicationScreenshots extends Component<any, any> {
    render() {
        return (
            <Container>
                <Img src={appPreview}/>
            </Container>
        )
    }
}

export default ApplicationScreenshots;
