import {plainToClass} from 'class-transformer';
import React, {PureComponent} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from 'recharts';
import * as api from '../infrastructure/api/CDNScoutAPI';
import {TransportProtocolDistributionDTO} from '../infrastructure/dto/TransportProtocolDistributionDTO';
import Typography from '@mui/material/Typography';
import '../styles/Global.css';
import {ApplicationDTO} from "../infrastructure/dto/ApplicationDTO";
import CssBaseline from "@mui/material/CssBaseline";
import {Container, Grid, List, ListItemButton} from "@mui/material";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];
const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} color="white" style={{fontWeight: "bold"}}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor}
                  fill={fill} style={{fontWeight: "bold"}}>{`${payload.name} ${(value).toFixed(2)}%`}</text>
            {/*{<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#041014">*/}
            {/*    {`(Rate ${(percent * 100).toFixed(2)}%)`}*/}
            {/*</text>}*/}
        </g>
    );
};

class TransportProtocolDistribution {
    name?: string;
    value?: number;
}

export default class TransportProtocolDistributionChart extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {transportProtocolDistribution: [], activeIndex: 0, applicationsUsingQUIC: []};
    }


    async componentDidMount() {
        const transportProtocolDistributionResponse = await api.getTransportProtocolDistribution();
        const applicationsUsingQUICResponse: [] = await api.getApplicationsUsingQUIC();

        let applicationsUsingQUIC: ApplicationDTO[] = plainToClass(ApplicationDTO, applicationsUsingQUICResponse);
        const chunkSize = 10;
        if (applicationsUsingQUIC.length > chunkSize) {
            applicationsUsingQUIC = applicationsUsingQUIC.slice(0, chunkSize)
        }
        this.setState({applicationsUsingQUIC: applicationsUsingQUIC})

        const transportProtocolDistribution: TransportProtocolDistribution[] = [];
        if (transportProtocolDistributionResponse != null) {
            let total = 0;
            transportProtocolDistributionResponse.forEach((protocol: any) => {
                total += plainToClass(TransportProtocolDistributionDTO, protocol).sumBytesIn!!;
            });

            transportProtocolDistributionResponse.forEach((protocol: any) => {
                const protocolObject = plainToClass(TransportProtocolDistributionDTO, protocol);

                const protocolPercentage: number = +(100 * protocolObject.sumBytesIn!! / total).toFixed(2)
                transportProtocolDistribution.push({"name": protocolObject.protocol, "value": protocolPercentage})
            });

            this.setState({transportProtocolDistribution: transportProtocolDistribution});
        }
    }

    onPieEnter = (_: any, index: any) => {
        this.setState({
            activeIndex: index,
        });
    };


    render() {
        return (
            <Container>
                <CssBaseline/>
                <Typography marginTop={"30px"} variant="h5" color="text.primary" align={"center"} marginBottom={"40px"}
                            style={{fontWeight: 600}}>
                    Fracción del tráfico por protocolo de transporte
                </Typography>
                <Grid container
                      flexDirection={"row-reverse"}
                      spacing={0}
                      justifyItems={"center"}
                      alignItems={"center"}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container justifyContent="center">
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart width={500} height={300}>
                                <Pie
                                    activeIndex={this.state.activeIndex}
                                    activeShape={renderActiveShape}
                                    onMouseEnter={this.onPieEnter}
                                    dataKey="value"
                                    isAnimationActive={true}
                                    data={this.state.transportProtocolDistribution}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={80}
                                    fill="#8884d8">
                                    {this.state.transportProtocolDistribution.map((_entry: any, index: number) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container justifyContent="center"
                          alignContent={'center'}>
                        <Typography variant="subtitle1" color="text.primary" align={"center"} marginBottom={"10px"}
                                    style={{wordWrap: "break-word"}}>
                            QUIC (Quick UDP Internet Connections o Conexiones UDP rápidas en Internet) es un protocolo
                            de
                            red experimental sobre la capa de transporte diseñado por Jim Roskind en Google,
                            inicialmente implementado en 2012, y anunciado como experimento ampliado en 2013.
                        </Typography>

                        <Typography variant="subtitle1" color="text.primary" align={"center"} marginBottom={"20px"}>
                            QUIC soporta un conjunto de conexiones multiplexadas entre dos extremos sobre UDP
                            (User Datagram Protocol), y fue diseñado para proveer seguridad equivalente a TLS/SSL,
                            junto con latencia de conexión y de transporte reducidas, y estimación de ancho de banda
                            en cada dirección para evitar la congestión. El principal objetivo de QUIC es mejorar el
                            rendimiento percibido de aplicaciones web orientadas a conexión que usan actualmente TCP.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}
