import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import FiubaFooter from "./FiubaFooter";

const hoverColor = '#DD5A9E'

function Footer() {
    return (
        <Container
            id="container-footer"
            maxWidth="md"
            component="footer"
            sx={{
                // borderTop: (theme) => `2px solid ${theme.palette.divider}`,
                borderTop: (theme) => `2px solid ${hoverColor}`,
                py: [1, 1],
            }}
            disableGutters
        >
            <CssBaseline/>
            <Grid container spacing={4} justifyContent="space-evenly">
                <Grid item xs={6} sm={3}>
                    <Typography variant="h6" color="text.primary" align={"center"} gutterBottom style={{fontWeight: 600}}>
                        Legal
                    </Typography>
                    <Typography variant="h6" color="text.secondary" align={"center"} gutterBottom>
                        <Link href="/terms.html" target="_blank" variant="subtitle1"
                              color="text.secondary">Términos de uso</Link>
                    </Typography>
                </Grid>
            </Grid>
            <FiubaFooter/>
        </Container>
    );
}

export default Footer;