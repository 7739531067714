import {plainToClass} from 'class-transformer';
import React, {PureComponent} from 'react';
import * as api from '../infrastructure/api/CDNScoutAPI';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import '../styles/Global.css';
import {Grid, List, ListItemButton} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import {ApplicationDTO} from "../infrastructure/dto/ApplicationDTO";
import AndroidIcon from "@mui/icons-material/Android";
import CssBaseline from "@mui/material/CssBaseline";

export default class QUICUsage extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {applicationsUsingQUIC: []};
    }


    async componentDidMount() {
        const applicationsUsingQUICResponse: [] = await api.getApplicationsUsingQUIC();
        let applicationsUsingQUIC: ApplicationDTO[] = []

        plainToClass(ApplicationDTO, applicationsUsingQUICResponse).forEach(app => {
            if (app.appName != null && app.appName !== '') {
                applicationsUsingQUIC.push(app!!)
            }
        });

        const chunkSize = 16;
        if (applicationsUsingQUIC.length > chunkSize) {
            applicationsUsingQUIC = applicationsUsingQUIC.slice(0, chunkSize)
        }

        this.setState({applicationsUsingQUIC: applicationsUsingQUIC})
    }

    render() {
        return (
            <Container>
                <CssBaseline/>
                <Typography variant="h5" color="text.primary" align={"center"} marginBottom={"10px"} style={{ fontWeight: 600 }}>
                    Aplicaciones que usan QUIC
                </Typography>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container justifyContent="center">
                    <List dense={false} sx={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateAreas: '"column-1 column-2"'}}>
                        {this.state.applicationsUsingQUIC.map((item: ApplicationDTO, _: number) => {
                            return (
                                <ListItem key={item.appPackage}>
                                    <ListItemButton component="a" target="_blank"
                                                    href={"https://play.google.com/store/apps/details?hl=es&id=" + item.appPackage}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                {item.appName == null &&
                                                    <AndroidIcon></AndroidIcon>
                                                }
                                                {item.appName != null &&
                                                    <div className="image-container">
                                                        <img src={item.appLogoURL} alt={""}/>
                                                    </div>
                                                }
                                            </Avatar>
                                        </ListItemAvatar>
                                        {item.appName == null &&
                                            <ListItemText
                                                primary={item.appPackage}
                                                secondary={" "}
                                            />
                                        }
                                        {item.appName != null &&
                                            <ListItemText
                                                primary={item.appName}
                                                secondary={"Categoría: " + item.appCategory}
                                            />
                                        }
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
            </Container>
        );
    }
}