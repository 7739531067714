import {Card, CardContent, Container, Grid} from "@mui/material";
import Typography from '@mui/material/Typography';
import {plainToClass} from 'class-transformer';
import React, {Component} from "react";
import * as api from '../infrastructure/api/CDNScoutAPI';
import {UsageSummaryDTO} from '../infrastructure/dto/UsageSummaryDTO';
import CssBaseline from "@mui/material/CssBaseline";
import iconApplication from '../img/icon-application.png';
import iconAS from '../img/icon-autonomous-system.png';
import iconUpstream from '../img/icon-upstream.png';
import iconDownstream from '../img/icon-downstream.png';
import iconUser from '../img/icon-user.png';

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to usemy
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: any, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

class UsageSummaryChart extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            applicationsUsage: '',
            totalUsers: '',
            totalUpstream: '',
            totalDownstream: '',
            totalSourceASes: '',
            totalDestinationASes: ''
        };

    }

    async componentDidMount() {
        const usageSummaryResponse = await api.getUsageSummary();
        const usageSummary = plainToClass(UsageSummaryDTO, usageSummaryResponse);

        this.setState({applicationsUsage: {"title": 'Aplicaciones', "value": String(usageSummary.applicationsCount)}});
        this.setState({totalUsers: {"title": 'Usuarios', "value": String(usageSummary.usersCount)}});
        this.setState({
            totalUpstream: {
                "title": 'Enviados',
                "value": `${humanFileSize(usageSummary.totalTraffic!!.sumBytesOut!!)}`
            }
        });
        this.setState({
            totalDownstream: {
                "title": 'Recibidos',
                "value": `${humanFileSize(usageSummary.totalTraffic!!.sumBytesIn!)}`
            }
        });
        this.setState({totalSourceASes: {"title": 'AS origen', "value": String(usageSummary.totalSourceASes)}});
        this.setState({
            totalDestinationASes: {
                "title": 'AS destino',
                "value": String(usageSummary.totalDestinationASes)
            }
        });
    }

    usageSummaryCard(icon: any, value: string, title: string) {
        return (
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid container xs={4}
                                  sx={{justifyContent: 'center', alignContent: 'center', minHeight: 100}}>
                                <img src={icon} style={{width: 60, height: 60}}/>
                            </Grid>
                            <Grid container xs={8} sx={{
                                justifyContent: 'center',
                                alignContent: 'center',
                                direction: 'column',
                                minHeight: 100
                            }}>
                                <Container>
                                    <Typography variant="h5" color="text.primary" align="center">
                                        {value}
                                    </Typography>
                                </Container>
                                <Container>
                                    <Typography variant="h6" color="text.secondary" align="center">
                                        {title}
                                    </Typography>
                                </Container>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    render() {
        return (
            <Container>
                <CssBaseline/>
                <Grid container spacing={2}>
                    {this.usageSummaryCard(iconApplication, this.state.applicationsUsage.value, this.state.applicationsUsage.title)}
                    {this.usageSummaryCard(iconUser, this.state.totalUsers.value, this.state.totalUsers.title)}
                    {this.usageSummaryCard(iconDownstream, this.state.totalDownstream.value, this.state.totalDownstream.title)}
                    {this.usageSummaryCard(iconUpstream, this.state.totalUpstream.value, this.state.totalUpstream.title)}
                    {this.usageSummaryCard(iconAS, this.state.totalSourceASes.value, this.state.totalSourceASes.title)}
                    {this.usageSummaryCard(iconAS, this.state.totalDestinationASes.value, this.state.totalDestinationASes.title)}
                </Grid>
            </Container>
        );
    }
}

export default UsageSummaryChart;
