const CDN_SCOUT_API_URL = 'https://cdn-scout-service.herokuapp.com/public'

// const CDN_SCOUT_API_URL = 'http://localhost:8080/public'

export async function getUsageSummary() {
    const url = CDN_SCOUT_API_URL + '/cdn-scout-usage-summary'
    console.log(`Calling ${url}`)
    const response = await fetch(url);

    if (!response.ok) {
        const message = `An error has occurred calling ${url}: ${response.status}`;
        throw new Error(message);
    }
    const number = await response.json();
    console.log(`Response from ${url} is: `, number)

    return number;
}

export async function getTransportProtocolDistribution() {
    const url = CDN_SCOUT_API_URL + '/transport-protocol-distribution'
    console.log(`Calling ${url}`)
    const response = await fetch(url);

    if (!response.ok) {
        const message = `An error has occurred calling ${url}: ${response.status}`;
        throw new Error(message);
    }
    const transportProtocolDistributionResponse = await response.json();
    console.log(`Response from ${url} is: `, transportProtocolDistributionResponse)

    return transportProtocolDistributionResponse;
}

export async function getTopDestinationAutonomousSystems() {
    const url = CDN_SCOUT_API_URL + '/top-destination-autonomous-systems'
    console.log(`Calling ${url}`)
    const response = await fetch(url);

    if (!response.ok) {
        const message = `An error has occurred calling ${url}: ${response.status}`;
        throw new Error(message);
    }
    const topDestinationAutonomousSystems = await response.json();
    console.log(`Response from ${url} is: `, topDestinationAutonomousSystems)

    return topDestinationAutonomousSystems;
}

export async function getTopApplicationsByIncomingData() {
    const url = CDN_SCOUT_API_URL + '/top-applications-by-incoming-data'
    console.log(`Calling ${url}`)
    const response = await fetch(url);

    if (!response.ok) {
        const message = `An error has occurred calling ${url}: ${response.status}`;
        throw new Error(message);
    }
    const topApplicationsByIncomingData = await response.json();
    console.log(`Response from ${url} is: `, topApplicationsByIncomingData)

    return topApplicationsByIncomingData;
}

export async function getApplicationsUsingQUIC() {
    const url = CDN_SCOUT_API_URL + '/applications-using-quic'
    console.log(`Calling ${url}`)
    const response = await fetch(url);

    if (!response.ok) {
        const message = `An error has occurred calling ${url}: ${response.status}`;
        throw new Error(message);
    }
    const applicationsUsingQUIC = await response.json();
    console.log(`Response from ${url} is: `, applicationsUsingQUIC)

    return applicationsUsingQUIC;
}

export async function getTopInNetworkCachedApplications() {
    const url = CDN_SCOUT_API_URL + '/top-in-network-cached-apps'
    console.log(`Calling ${url}`)
    const response = await fetch(url);

    if (!response.ok) {
        const message = `An error has occurred calling ${url}: ${response.status}`;
        throw new Error(message);
    }
    const inNetworkCachedApplicationsRank = await response.json();
    console.log(`Response from ${url} is: `, inNetworkCachedApplicationsRank)

    return inNetworkCachedApplicationsRank;
}

/**
 * Manu
 */
export async function getTopDestinationAsesByAppCount() {
    const url = CDN_SCOUT_API_URL + '/top-destination-autonomous-systems-by-apps-count'
    console.log(`Calling ${url}`)
    const response = await fetch(url);

    if (!response.ok) {
        const message = `An error has occurred calling ${url}: ${response.status}`;
        throw new Error(message);
    }
    const topDestinationAsesByAppCount = await response.json();
    console.log(`Response from ${url} is: `, topDestinationAsesByAppCount)

    return topDestinationAsesByAppCount;
}