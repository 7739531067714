import Typography from '@mui/material/Typography';
import React, {Component} from "react";
import {Container} from "@mui/material";

class MainDescription extends Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <Container>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                    style={{ fontWeight: 600 }}
                >
                    CDN Scout
                </Typography>

                <Typography variant="h5" align="center" color="text.primary" style={{ fontWeight: 600 }} component="p" sx={{my: [5,5]}}>
                    Una perspectiva de internet desde dispositivos móviles
                </Typography>

                <Typography variant="h6" align="center" color="text.secondary" component="p">
                    Es una plataforma colaborativa desarrollada con fines académicos que permite analizar el tráfico y descubrir hacia donde van los datos enviados/recibidos por las aplicaciones presentes en los dispositivos móviles.
                </Typography>

            </Container>
        );
    }
}

export default MainDescription;
