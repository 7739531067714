import React, {Component} from "react";
import * as api from "../infrastructure/api/CDNScoutAPI";
import {Container} from "@mui/material";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
    Line, Label
} from 'recharts';
import {TopDestAsesByAppCountDTO} from "../infrastructure/dto/TopDestAsesByAppCountDTO";
import Typography from "@mui/material/Typography";

const whiteColor = '#fff';

class TopDestinationAsesByAppsCountBarChart extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {topDestinationAutonomousSystem: []}
    }

    async componentDidMount() {
        const topDestinationAutonomousSystemsResponse: [] = await api.getTopDestinationAsesByAppCount()

        this.setState({
            topDestinationAutonomousSystem: topDestinationAutonomousSystemsResponse
        })
    }

    render() {
        let sortedData = this.state.topDestinationAutonomousSystem
            .sort((a, b: TopDestAsesByAppCountDTO) => a.numberOfApps < b.numberOfApps ? 1 : -1)
            .slice(0, 10)

        return (
            <Container>
                <Typography variant="h5" color="text.primary" align={"center"} marginBottom={"10px"}
                            style={{fontWeight: 600}}>
                    Sistemas Autónomos más utilizados
                </Typography>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart height={250} data={sortedData}
                              margin={{
                                  top: 50,
                                  bottom: 50,
                              }}
                              barGap={20}>
                        <YAxis/>
                        <CartesianGrid
                            horizontal={true}
                            vertical={false}
                            strokeDasharray="10 10"
                            color={"rgba(10, 10, 10, 0.2)"} stroke={"rgba(184, 184, 184, 0.2)"}/>
                        <Tooltip cursor={false}/>
                        <Bar dataKey="numberOfApps" name={"Cantidad de apps"} fill="#DD5A9E" >
                            <LabelList
                                dataKey="asOrganization"
                                position="top"
                                fill={whiteColor}/>
                        </Bar>
                        <XAxis tickLine={false} tickFormatter={_ => ""}>
                            <Label value="Cantidad de apps" position="bottom" style={{fill: whiteColor}}/>
                        </XAxis>
                    </BarChart>
                </ResponsiveContainer>
            </Container>
        )
    }
}

export default TopDestinationAsesByAppsCountBarChart