import Container from '@mui/material/Container';
import * as React from 'react';
import '../styles/Global.css';
import UsageSummaryChart from './UsageSummaryChart';
import ApplicationScreenshots from './ApplicationScreenshots';
import Footer from './Footer';
import Header from './Header';
import TransportProtocolDistributionChart from './TransportProtocolDistributionChart';
import TopDestinationAutonomousSystemsChart from './TopDestinationAutonomousSystemsChart';
import {createTheme, ThemeProvider} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MainDescription from "./MainDescription";
import DownloadApplication from "./DownloadApplication";
import TopDestinationAsesByAppsCountBarChart from "./TopDestinationAsesByAppsCountBarChart";
import TopInNetworkCachedApplications from "./TopInNetworkCachedApplications";
import QUICUsage from "./QUICUsage";
import MostConsumingApps from "./MostConsumingApps";

// Required for module augmentation
declare module '@mui/material/styles' {
    interface Theme {
    }

    interface ThemeOptions {
    }
}

const customTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            // main: '#6f6fc7',
            main: '#1E1E1E',
        },
        // secondary: {
        //     main: '#f50057',
        // },
        // background: {
        //     default: '#413f3f',
        // },
        // text: {
        //     primary: '#ffffff',
        //     secondary: 'rgba(246,246,246,0.7)',
        // },
    },
});


function Home() {
    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline/>
            <React.Fragment>
                <Header></Header>
                <Container id="container-main-description" disableGutters maxWidth="sm" component="main"
                           sx={{py: [3, 6],}}>
                    <MainDescription/>
                </Container>
                {/* <Container id="container-download-application" sx={{py: [3, 3],}}>
                    <DownloadApplication/>
                </Container> */}
                <Container id="container-app-screenshots" sx={{py: [3, 6],}}>
                    <ApplicationScreenshots/>
                </Container>
                <Container id="container-usage-summary" sx={{py: [3, 6],}}>
                    <UsageSummaryChart/>
                </Container>
                <Container id="container-most-consuming-apps">
                    <MostConsumingApps></MostConsumingApps>
                </Container>
                <Container id="container-transport-distribution" sx={{py: [3, 3],}}>
                    <TransportProtocolDistributionChart></TransportProtocolDistributionChart>
                </Container>
                <Container id="container-quic-usage" sx={{py: [3, 3],}}>
                    <QUICUsage></QUICUsage>
                </Container>
                <Container id="container-top-destination-ases" sx={{py: [3, 6],}}>
                    <TopDestinationAutonomousSystemsChart></TopDestinationAutonomousSystemsChart>
                </Container>
                <Container id="container-top-destination-ases">
                    <TopDestinationAsesByAppsCountBarChart/>
                </Container>
                <Container id="container-top-in-network" sx={{py: [3, 6],}}>
                    <TopInNetworkCachedApplications/>
                </Container>
                <Footer></Footer>
            </React.Fragment>
        </ThemeProvider>
    );
}

export default Home;