import styled from "styled-components";

export const NavbarWrapper = styled.nav`
  display: ${props => (props.open ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #1E1E1E;
  position: fixed;
  top: 5vh;
  right: ${props => (props.open ? "0" : "-100%")};
  width: 100%;
  height: 90vh;
  transition: right 0.3s linear;
  z-index: 1;
  height: 100%;

  @media only screen and (min-width: 624px) {
    flex-direction: row;
    position: initial;
    height: 7vh;
    justify-content: center;
    //border-bottom: 2px solid black';
    display: flex;
  }

`;